import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const DocumentTypeProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: '',
  });

  const {
    documentType,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (documentType) {
      setValues({
        name: documentType.name
      });
    }
  }, [documentType]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase()
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (documentType) {
      await updateform(documentType.id, formData);
    } else {
      await saveform(formData);
    }
    navigate('/app/documentTypes', { replace: true });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados do Documento"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

DocumentTypeProfileDetails.propTypes = {
  documentType: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default DocumentTypeProfileDetails;
