import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import OrderProfileDetails from 'src/components/order/OrderProfileDetails';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OrderContext } from 'src/context/OrderContext';
import { ProductContext } from 'src/context/ProductContext';
import { TechnicianContext } from 'src/context/TechnicianContext';
import { OrderServiceContext } from 'src/context/OrderServiceContext';
import { ClientContext } from 'src/context/ClientContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import TitleComponent from 'src/components/TitleComponent';
import { ConfigContext } from 'src/context/ConfigContext';
import { OrderContactContext } from 'src/context/OrderContactContext';
import OrderContactListResults from 'src/components/order/OrderContactListResult';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const OrderForm = () => {
  const { id, productId } = useParams();
  const {
    status,
    order,
    setOrder,
    getOrder,
    saveOrder,
    updateOrder,
    getStatus,
    setChangeStatusOrder,
    setCanceldOrder,
  } = useContext(OrderContext);
  const {
    orderContacts, saveOrderContact, getOrderContact, orderContact, paginateOrderContact, getOrderContactByOrder, deleteOrderContact,
    updateOrderContact
  } = useContext(OrderContactContext);

  const { setReadNotification } = useContext(NotificationsContext);

  const {
    productsAll, getAllProductsByClient, getProduct, product
  } = useContext(ProductContext);
  const { clientsAll, getAllClients, searchClients } = useContext(ClientContext);
  const {
    deleteOrder,
    getOrderService,
    orderService,
    saveOrderService,
    updateOrderService,
  } = useContext(OrderServiceContext);

  const {
    techniciansAll,
    getAllTechnicians
  } = useContext(TechnicianContext);
  const {
    config,
    getConfig
  } = useContext(ConfigContext);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    await getStatus();
    await getAllClients();
    await getAllTechnicians();
    await getConfig(1);
    if (id) {
      await getOrder(id);
      await setReadNotification(id);
      await getOrderContactByOrder(id);
    } else {
      setOrder(null);
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (techniciansAll && status) {
        setLoading(false);
      }
    }
  }, [productsAll, techniciansAll, status]);

  useEffect(() => {
    if (!id) {
      if (techniciansAll && status) {
        setLoading(false);
      }
    }
  }, [productsAll, techniciansAll, status]);

  return (
    <>
      <Helmet>
        <title>
          {`Order | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <TitleComponent title="Dados da Ordem" />
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                      <Tab style={value === 1 ? tabSelected : tab} label="Histórico de Contatos" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <OrderProfileDetails
                      clients={clientsAll}
                      config={config}
                      getProduct={getProduct}
                      product={product}
                      getProductsByClient={getAllProductsByClient}
                      searchClients={searchClients}
                      productId={productId}
                      reload={getOrder}
                      order={order}
                      products={productsAll}
                      status={status}
                      saveform={saveOrder}
                      updateform={updateOrder}
                      technicians={techniciansAll}
                      deleteService={deleteOrder}
                      getService={getOrderService}
                      service={orderService}
                      saveService={saveOrderService}
                      updateService={updateOrderService}
                      setChangeStatusOrder={setChangeStatusOrder}
                      setCancel={setCanceldOrder}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                    <OrderContactListResults
                      order={order}
                      contacts={orderContacts}
                      exclude={deleteOrderContact}
                      edit={updateOrderContact}
                      save={saveOrderContact}
                      paginate={paginateOrderContact}
                      reload={getOrderContactByOrder}
                      orderContact={orderContact}
                      getContactById={getOrderContact}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default OrderForm;
