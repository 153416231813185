import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ClientContext } from 'src/context/ClientContext';
import QuickOrderDetails from 'src/components/order/QuickOrderDetails';
import { DocumentTypeContext } from 'src/context/DocumentTypeContext';
import { OrderContext } from 'src/context/OrderContext';
import TitleComponent from 'src/components/TitleComponent';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const QuickOrderForm = () => {
  const {
    clients,
    searchClients,
    paginateClient,
    findByPhoneClient,
    notifyMessageError,
    findByDocumentClient
  } = useContext(ClientContext);
  const { documentTypesAll, getAllDocumentTypes } = useContext(DocumentTypeContext);
  const [value, setValue] = useState(0);
  const {
    saveQuickOrder,
  } = useContext(OrderContext);

  useEffect(async () => {
    await getAllDocumentTypes();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Order | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <TitleComponent title="Abrir Order Rápida" />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab style={value === 0 ? tabSelected : tab} label="Abrir Ordem Rápida" {...a11yProps(0)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                <QuickOrderDetails
                  clients={clients}
                  paginate={paginateClient}
                  saveform={saveQuickOrder}
                  searchClients={searchClients}
                  typeDocuments={documentTypesAll}
                  notifyMessageError={notifyMessageError}
                  findByPhoneClient={findByPhoneClient}
                  findByDocumentClient={findByDocumentClient}
                />
              </TabPanel>
              <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                Historico
              </TabPanel>
            </Grid>
          </Grid>

        </Container>
      </Box>
    </>
  );
};
export default QuickOrderForm;
