class Helpers {
  /**
     * Adiciona um zero à esquerda se o número for menor que 10
     * @param {number} num - Número a ser formatado
     * @returns {string} - Número formatado com dois dígitos
     */
  static pad(num) {
    return String(num).padStart(2, '0');
  }

  /**
     * Converte a entrada para um objeto Date válido
     * @param {Date | string | null} date - Data a ser convertida
     * @returns {Date | null} - Objeto Date ou null se a entrada for inválida
     */
  static parseDate(date) {
    if (!date) return null;

    const parsedDate = date instanceof Date ? date : new Date(date);

    return Number.isNaN(parsedDate.getTime()) ? null : parsedDate;
  }

  /**
     * Formata uma data para o formato "Y-M-D H:i:s"
     * @param {Date | string | null} date - Data a ser formatada
     * @returns {string | null} - Data formatada ou null se a entrada for inválida
     */
  static formatToYMD(date) {
    const d = Helpers.parseDate(date);
    if (!d) return null;

    return `${d.getFullYear()}-${Helpers.pad(d.getMonth() + 1)}-${Helpers.pad(d.getDate())} `
             + `${Helpers.pad(d.getHours())}:${Helpers.pad(d.getMinutes())}:${Helpers.pad(d.getSeconds())}`;
  }

  /**
     * Formata uma data para o formato "D/M/Y H:i:s"
     * @param {Date | string | null} date - Data a ser formatada
     * @returns {string | null} - Data formatada ou null se a entrada for inválida
     */
  static formatToDMY(date) {
    const d = Helpers.parseDate(date);
    if (!d) return null;

    return `${Helpers.pad(d.getDate())}/${Helpers.pad(d.getMonth() + 1)}/${d.getFullYear()} `
             + `${Helpers.pad(d.getHours())}:${Helpers.pad(d.getMinutes())}:${Helpers.pad(d.getSeconds())}`;
  }
}

export default Helpers;
