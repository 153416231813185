import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';

const DocumentTypeListToolbar = (props) => {
  const navigate = useNavigate();
  const { search } = props;
  const [values, setValues] = useState('');

  const sendSearch = async (param) => {
    await search(param);
  };
  const handleChange = async (event) => {
    setValues(
      event.target.value.toUpperCase()
    );
    sendSearch(event.target.value.toUpperCase());
  };

  const buttonNew = () => {
    navigate('/app/documentType', { replace: true });
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={buttonNew}
        >
          Adicionar Documento
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                onChange={handleChange}
                value={values}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Pesquisar"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

DocumentTypeListToolbar.propTypes = {
  search: PropTypes.isRequired
};

export default DocumentTypeListToolbar;
