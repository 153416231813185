import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
  colors,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router';

const TrafficByDevice = ({ status }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const values = [];
  const labels = [];
  const colorPie = [];
  const devicesData = [];
  const colorStatus = [
    {
      status: 'Aguardando Orçamento',
      color: '#F5A623' // Laranja suave, indicando algo pendente
    },
    {
      status: 'Orçamento Finalizado',
      color: '#007AFF' // Azul médio, associado à conclusão
    },
    {
      status: 'Orçamento Aprovado',
      color: '#34C759' // Verde brilhante, indicando sucesso
    },
    {
      status: 'Serviço Iniciado',
      color: '#5856D6' // Roxo vibrante, associado a progresso
    },
    {
      status: 'Serviço Finalizado',
      color: '#FFCC00' // Amarelo forte, indicando atenção e término
    },
    {
      status: 'Aguardando Retirada',
      color: '#FF9500' // Laranja vibrante, indicando expectativa
    },
    {
      status: 'Order Finalizado',
      color: '#28A745' // Verde escuro, reforçando conclusão
    },
    {
      status: 'Order Cancelado',
      color: '#FF3B30' // Vermelho forte, associado a erros ou cancelamentos
    }
  ];

  // eslint-disable-next-line no-shadow
  const filterByStatus = (status) => {
    navigate(`/app/orders/status/${status}`, { replace: true });
  };

  // eslint-disable-next-line array-callback-return
  status.map((item) => {
    const totalItens = status.reduce((a, e) => a + e.amount, 0);
    const color = colorStatus.find((i) => i.status === item.status_description);
    values.push(item.amount);
    labels.push(item.status);
    colorPie.push(color.color);

    devicesData.push({
      title: item.status_description,
      status: item.status,
      value: ((item.amount / totalItens) * 100).toFixed(0),
      color: color.color
    });
  });

  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: colorPie,
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    }
  };

  const devices = devicesData;

  return (
    <Card>
      <CardHeader title="Status das Ordens" />
      <Divider />
      <CardContent>
        <Grid
          container
        >
          <Grid item>
            <Box
              sx={{
                height: 300,
                position: 'relative'
              }}
            >
              <Doughnut
                data={data}
                options={options}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2,
                pl: 10
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {devices.map(({
                  // eslint-disable-next-line no-shadow
                  color, title, status, value
                }) => (
                  <Paper
                    onClick={() => filterByStatus(status)}
                    key={title}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: 1,
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1 }}>
                      <Typography variant="h3" sx={{ color }}>
                        {value}
                        %
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 3 }}>
                      <Typography sx={{ fontWeight: 'bold', color }} variant="body1" color="textPrimary">
                        {title}
                      </Typography>
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  status: PropTypes.array.isRequired,
};
export default TrafficByDevice;
