import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const OrderContactContext = createContext();

const OrderContactProvider = (prop) => {
  const baseUrl = 'user/orderContact';
  const { children } = prop;
  const [orderContact, setOrderContact] = useState(null);
  const [orderContacts, setOrderContacts] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getOrderContactByOrder = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/byOrder/${id}`)
      .then((response) => {
        setOrderContacts(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getOrderContact = async (id) => {
    setLoading(true);
    const data = await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setOrderContact(response.data.data);
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
      });
    return data;
  };

  const paginateOrderContact = async (number) => {
    setLoading(true);
    await api.get(`${urlPaginate}?page=${number}`)
      .then((response) => {
        setOrderContacts(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setOrderContacts([]);
        setLoading(false);
        notifyMessageError('Paginar', err);
      });
  };

  const saveOrderContact = async (data, orderId) => {
    setLoading(true);
    return api.post(baseUrl, data)
      .then((response) => {
        setOrderContact(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        getOrderContactByOrder(orderId);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
  };

  const updateOrderContact = async (id, data, orderId) => {
    setLoading(true);
    return api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setOrderContact(response.data.data);
        getOrderContactByOrder(orderId);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const deleteOrderContact = async (id, orderId) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getOrderContactByOrder(orderId);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  return (
    <OrderContactContext.Provider
      value={{
        orderContact,
        orderContacts,
        loading,
        urlPaginate,
        getOrderContactByOrder,
        setOrderContact,
        deleteOrderContact,
        paginateOrderContact,
        saveOrderContact,
        updateOrderContact,
        getOrderContact,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </OrderContactContext.Provider>

  );
};

export default OrderContactProvider;
