import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import DocumentTypeProfileDetails from 'src/components/documentType/DocumentTypeProfileDetails';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DocumentTypeContext } from 'src/context/DocumentTypeContext';
import TitleComponent from 'src/components/TitleComponent';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const DocumentTypeForm = () => {
  const { id, documentTypeId } = useParams();
  const {
    documentType,
    getDocumentType,
    saveDocumentType,
    updateDocumentType,
    setDocumentType,
  } = useContext(DocumentTypeContext);

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (id) {
      getDocumentType(id);
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (documentType) {
        setLoading(false);
      }
    }

    if (!id) {
      setDocumentType(null);
      setLoading(false);
    }
  }, [documentType]);

  return (
    <>
      <Helmet>
        <title>
          {`Tipo de Documento | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <TitleComponent title="Tipos de Documentos" />
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <DocumentTypeProfileDetails
                      documentType={documentType}
                      documentTypeId={documentTypeId}
                      saveform={saveDocumentType}
                      updateform={updateDocumentType}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default DocumentTypeForm;
