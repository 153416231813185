import { Helmet } from 'react-helmet';
import {
  Box, Container, Grid, Typography
} from '@mui/material';
import ClientListResults from 'src/components/client/ClientListResults';
import ClientListToolbar from 'src/components/client/ClientListToolbar';
import { ClientContext } from 'src/context/ClientContext';
import { useContext, useEffect } from 'react';
import TitleComponent from 'src/components/TitleComponent';

const ClientList = () => {
  const {
    clients,
    setClient,
    getClients,
    deleteClient,
    searchClients,
    paginateClient,
  } = useContext(ClientContext);

  useEffect(() => {
    setClient(null);
    getClients();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Clientes | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <TitleComponent title="Lista de Clientes" />
          <ClientListToolbar search={searchClients} />
          <Box sx={{ pt: 3 }}>
            {clients.data && clients.data.length > 0
              ? <ClientListResults clients={clients} exclude={deleteClient} paginate={paginateClient} />
              : (
                <>
                  <Grid container alignContent="center" justifyContent="center" alignItems="center" lg={12}>
                    <Typography variant="h3">
                      Nenhum registro encontrado
                    </Typography>
                  </Grid>
                </>
              )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default ClientList;
