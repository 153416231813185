import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField
} from '@mui/material';
import { ClientContext } from 'src/context/ClientContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function OrderContactModal({ modal }) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    id: '',
    order_id: '',
    client_contact_id: '',
    whatsapp: false,
    success: false,
    description: '',
  });
  const [contacts, setContacts] = useState([]); // Estado para armazenar os contatos
  const { getClientContactByClientNoPaginate } = useContext(ClientContext);

  const handleChange = (event) => {
    const {
      name, value, checked, type
    } = event.target;

    setValues({
      ...values,
      [name]: type === 'checkbox' ? checked : value.toUpperCase()
    });
  };

  // Função para carregar os contatos
  const loadOptions = async () => {
    const response = await getClientContactByClientNoPaginate(modal.client_id);
    if (response.data && response.data.length > 0) {
      setContacts(response.data); // Armazenando os contatos no estado
    } else {
      setContacts([]); // Caso não haja contatos, define o estado como vazio
    }
  };

  useEffect(() => {
    setOpen(modal.open);
  }, [modal]);

  // Carregar as opções de contato quando o modal abrir ou o client_id mudar
  useEffect(() => {
    if (modal.client_id) {
      loadOptions();
    }
  }, [modal.client_id]);

  useEffect(() => {
    if (modal.orderContact) {
      setValues({
        ...values,
        id: modal.orderContact.id,
        order_id: modal.order_id,
        client_contact_id: modal.orderContact.client_contact.id,
        whatsapp: modal.orderContact.whatsapp,
        success: modal.orderContact.success,
        description: modal.orderContact.description,
      });
    } else {
      setValues({
        id: '',
        order_id: modal.order_id,
        client_contact_id: '',
        whatsapp: false,
        success: false,
        description: '',
      });
    }
  }, [modal]);

  const handleConfirm = () => {
    modal.confirm(values);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {modal.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="client-contact-label">Contato Usado</InputLabel>
                <Select
                  labelId="client-contact-label"
                  id="client-contact-select"
                  value={values.client_contact_id || ''} // Definindo valor inicial corretamente
                  onChange={(event) => setValues({ ...values, client_contact_id: event.target.value })}
                >
                  {contacts.length > 0 ? (
                    contacts.map((contact) => (
                      <MenuItem key={contact.id} value={contact.id}>
                        {contact.name}
                        {' - '}
                        {contact.contact}

                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Nenhum contato disponível</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Switch name="whatsapp" checked={!!values.whatsapp} onChange={handleChange} />} label="Contato via WhatsApp" />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Switch name="success" checked={!!values.success} onChange={handleChange} />} label="Conseguiu contato?" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Descrição"
                name="description"
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleClose} color="default">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

OrderContactModal.propTypes = {
  modal: PropTypes.object
};
