import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import {
  Edit as EditIcon,
  Trash2 as TrashIcon
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import DeleteModal from 'src/components/modal/DeleteModal';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const ClientListResults = ({
  clients,
  exclude,
  paginate,
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Cliente ?',
  });

  const excludeClient = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const buttonEdit = (id) => {
    navigate(`/app/client/${id}`, { replace: true });
  };

  const deleteClient = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeClient,
      cancel: () => { }
    });
  };

  return (
    <>
      <Box sx={{ minWidth: 1050 }}>
        {isSmallScreen ? (
          <Grid
            container
            spacing={3}
          >
            { clients.data.slice(0, limit).map((client) => (
              <Grid item xs={12} key={client.id} md={3} lg={3}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      sx={{ height: 140 }}
                      alt={client.name}
                      cover={false}
                      image={client.image ? client.image : '/image/no-image.png'}
                      title={client.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Tipo
                        {' '}
                        <Chip
                          size="small"
                          label={client.typeDescription}
                          color={client.type === 'P' ? 'primary' : 'success'}
                        />
                      </Typography>
                      <Typography gutterBottom variant="h5" component="h2">
                        {client.name}
                      </Typography>
                      <Typography variant="h6">{`${client.city} - ${client.neighborhood}`}</Typography>
                      <Typography variant="h6">
                        {client.documentType.name}
                        {' '}
                        -
                        {client.document}
                      </Typography>
                      {client.deleted && <Chip size="small" label="Cliente Excluído" color="secondary" />}
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button onClick={() => buttonEdit(client.id)} title="Editar" fullWidth variant="contained" color="primary">
                      <EditIcon />
                    </Button>
                    {!client.deleted && (
                      <Button onClick={() => deleteClient(client.id)} title="Excluir" fullWidth variant="contained" color="error">
                        <TrashIcon />
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Endereço</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.data.slice(0, limit).map((client) => (
                  <TableRow key={client.id}>
                    <TableCell>
                      <Chip
                        size="small"
                        label={client.typeDescription}
                        color={client.type === 'P' ? 'primary' : 'success'}
                      />
                    </TableCell>
                    <TableCell>{client.name}</TableCell>
                    <TableCell>
                      {`${client.city} - ${client.neighborhood}`}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {client.documentType.name}
                      {' '}
                      -
                      {client.document}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => buttonEdit(client.id)} variant="contained" color="primary">
                        <EditIcon />
                      </Button>
                      {!client.deleted && (
                        <Button onClick={() => deleteClient(client.id)} variant="contained" color="error">
                          <TrashIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}

      </Box>
      <TablePagination
        component="div"
        count={clients.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <DeleteModal modal={deleteModal} />
    </>
  );
};

ClientListResults.propTypes = {
  clients: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default ClientListResults;
