import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Edit as EditIcon,
  Trash2 as TrashIcon,
} from 'react-feather';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import EmailIcon from '@mui/icons-material/Email';
import Helpers from '../../utils/helpers';
import DeleteModal from '../modal/DeleteModal';
import OrderContactModal from '../modal/OrderContactModal';

const OrderContactListResults = ({
  order,
  contacts,
  exclude,
  paginate,
  reload,
  edit,
  save,
  disabled,
  orderContact,
  getContactById,
  ...rest
}) => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Serviço ?',
  });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [orderContactModal, setOrderContactModal] = useState({
    open: false,
    orderContact: null,
    order_id: null,
    client_id: null,
    title: 'Cadastrar serviços',
  });

  const excludeContact = async (id) => {
    const confirm = await exclude(id, order.id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
      await reload();
    }
  };

  const editContact = async (id, values) => {
    const response = await edit(id, values, order.id);
    if (response) {
      setOrderContactModal({
        ...orderContactModal,
        open: false,
      });
    }
  };

  const saveContact = async (values) => {
    const response = await save(values, order.id);
    if (response) {
      setOrderContactModal({
        ...orderContactModal,
        open: false,
      });
    }
  };

  const buttonEdit = async (id) => {
    const response = await getContactById(id);
    setOrderContactModal({
      ...orderContactModal,
      open: true,
      order_id: order.id,
      orderContact: response,
      client_id: order && order.client.id,
      title: 'Editar Historico de Contato',
      confirm: (values) => { editContact(id, values, order.id); },
    });
  };

  const buttonNew = async () => {
    setOrderContactModal({
      ...orderContactModal,
      open: true,
      order_id: order.id,
      orderContact: null,
      client_id: order && order.client.id,
      title: 'Criar Historico de contato',
      confirm: (values) => { saveContact(values); },
    });
  };

  const deleteContact = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeContact,
      cancel: () => { }
    });
  };

  const identifyInputType = (input) => {
    const phoneRegex = /^\(?\d{2}\)?\s?\d{4,5}-\d{4}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (phoneRegex.test(input)) {
      return 'phone';
    } if (emailRegex.test(input)) {
      return 'mail';
    }
    return 'other';
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate(newPage + 1);
  };

  return (
    <Card {...rest}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={buttonNew}
        >
          Adicionar Registro
        </Button>
      </Box>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Usuário
                </TableCell>
                <TableCell align="center">
                  Telefone
                </TableCell>
                <TableCell>
                  Forma de Contato
                </TableCell>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
                { !disabled
                  ? (
                    <>
                      <TableCell>
                        Excluir
                      </TableCell>
                    </>
                  )
                  : <></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.data.map((contact) => (
                <TableRow
                  hover
                  key={contact.id}
                >
                  <TableCell>
                    {contact.user.name}
                  </TableCell>
                  <TableCell>
                    {contact.client_contact.contact}
                  </TableCell>
                  <TableCell align="center">
                    {(() => {
                      switch (identifyInputType(contact.client_contact.contact)) {
                        case 'phone':
                          return <Chip title={contact.success ? 'Sucesso' : 'Falha'} label={contact.whatsapp ? <WhatsAppIcon color={contact.success ? 'success' : 'error'} /> : <PhoneForwardedIcon color={contact.success ? 'success' : 'error'} />} />;
                        case 'mail':
                          return <Chip title={contact.success ? 'Sucesso' : 'Falha'} label={<EmailIcon color={contact.success ? 'success' : 'error'} />} />;
                        default:
                          return <Chip title={contact.success ? 'Sucesso' : 'Falha'} label={<SwapVerticalCircleIcon color={contact.success ? 'success' : 'error'} />} />;
                      }
                    })()}
                  </TableCell>
                  <TableCell>
                    {Helpers.formatToDMY(contact.date)}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { buttonEdit(contact.id); }}><EditIcon /></Button>
                  </TableCell>
                  { !disabled
                    ? (
                      <>
                        <TableCell>
                          <Button onClick={() => { deleteContact(contact.id); }}><TrashIcon /></Button>
                        </TableCell>
                      </>
                    )
                    : <></>}
                </TableRow>
              ))}
              {contacts.data.length === 0 && (
              <TableRow
                hover
                key={1}
              >
                <TableCell colSpan={7} align="center">
                  <Typography variant="h5">Nenhum registro encontrado</Typography>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={contacts.meta.total}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[]}
          />
          <DeleteModal modal={deleteModal} />
        </Box>
        <OrderContactModal modal={orderContactModal} />
      </PerfectScrollbar>
    </Card>
  );
};

OrderContactListResults.propTypes = {
  contacts: PropTypes.array.isRequired,
  exclude: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  paginate: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  orderContact: PropTypes.object.isRequired,
  getContactById: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default OrderContactListResults;
