import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const OrderContext = createContext();

const OrderProvider = (prop) => {
  const baseUrl = 'user/order';
  const { children } = prop;
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    notifyMessage,
    setNotifyMessage,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getOrders = async () => {
    setLoading(true);
    await api.get(baseUrl)
      .then((response) => {
        setOrders(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getStatus = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/status`)
      .then((response) => {
        setStatus(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar status', err);
        return false;
      });
  };

  const getOrderPublic = async (id) => {
    setLoading(true);
    await api.get(`order/${id}`)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const getOrder = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar', err);
        return false;
      });
  };

  const searchOrders = async (param) => {
    setLoading(true);
    if (!param) {
      await getOrders();
    } else {
      await api.post(`${baseUrl}/search`, param)
        .then((response) => {
          setOrders(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setOrders([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    }
  };

  const paginateOrder = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, search)
        .then((response) => {
          setOrders(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setOrders([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setOrders(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setOrders([]);
          setLoading(false);
          notifyMessageError('Paginar', err);
        });
    }
  };

  const saveOrder = async (data) => {
    setLoading(true);
    const saved = await api.post(baseUrl, data)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return saved;
  };

  const saveQuickOrder = async (data) => {
    setLoading(true);
    const saved = await api.post(`${baseUrl}/quickOrder`, data)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Salvar', err);
        return false;
      });
    return saved;
  };

  const updateOrder = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/${id}`, data)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const setChangeStatusOrder = async (id, orderStatus) => {
    setLoading(true);
    await api.post(`${baseUrl}/changeStatus/${id}/${orderStatus}`)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const setCanceldOrder = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/setCanceled/${id}`, data)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Atualizar', err);
        return false;
      });
  };

  const deleteOrder = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getOrders();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Excluir', err);
        return false;
      });
    return response;
  };

  const getOrdersByProduct = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/product/${id}`)
      .then((response) => {
        setOrders(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar por produto', err);
        return false;
      });
  };

  const getOrdersByClient = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/client/${id}`)
      .then((response) => {
        setOrders(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar por client', err);
        return false;
      });
  };

  const getOrdersByTechnician = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/technician/${id}`)
      .then((response) => {
        setOrders(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        notifyMessageError('Visualizar por client', err);
        return false;
      });
  };

  const searchOrdersByClient = async (param) => {
    setLoading(true);
    if (!param) {
      await getOrders();
    } else {
      await api.post(`${baseUrl}/search/client`, param)
        .then((response) => {
          setOrders(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setOrders([]);
          setLoading(false);
          notifyMessageError('Pesquisar', err);
        });
    }
  };

  return (
    <OrderContext.Provider
      value={{
        order,
        orders,
        loading,
        status,
        urlPaginate,
        getOrdersByProduct,
        getOrders,
        saveOrder,
        updateOrder,
        setOrder,
        getOrderPublic,
        getStatus,
        deleteOrder,
        paginateOrder,
        getOrdersByClient,
        getOrdersByTechnician,
        searchOrdersByClient,
        setChangeStatusOrder,
        setCanceldOrder,
        getOrder,
        saveQuickOrder,
        setNotifyMessage,
        searchOrders,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </OrderContext.Provider>

  );
};

export default OrderProvider;
