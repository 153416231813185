import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TitleComponent = ({ title }) => (
  <Typography variant="h1" sx={{ pt: 1, pb: 2 }}>{title}</Typography>
);

TitleComponent.propTypes = {
  title: PropTypes.string,
};

export default TitleComponent;
