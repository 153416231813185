/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Select, TextField, Typography, TablePagination
} from '@mui/material';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SelectIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import states from 'src/utils/states';
import { useNavigate } from 'react-router';

const QuickOrderDetails = (props) => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [open, setOpen] = useState(false); // Estado para controlar o modal
  const [selectedValue, setSelectedValue] = useState({
    name: '',
    alias: '',
    phone: '',
    document_type_id: '',
    document: '',
    city: '',
    state: '',
    product_name: '',
    code: '',
    model: '',
    brand: '',
    tension: '220V',
    nf: '',
    date_nf: '',
    purchased: '',
    client_id: null
  });
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [fieldsValidation, setFieldsValidation] = useState({
    client_id: false,
    name: false,
    phone: false,
    document_type_id: false,
    document: false,
    city: false,
    state: false,
    product_name: false,
    model: false,
    brand: false,
    tension: false,
  });

  const {
    searchClients,
    paginate,
    clients,
    typeDocuments,
    notifyMessageError,
    saveform,
    findByPhoneClient
  } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const hasErrorFields = () => {
    let hasError = false;
    const validation = { ...fieldsValidation };

    // Percorre todos os campos de selectedValue
    Object.keys(fieldsValidation).forEach((key) => {
      if (!selectedValue[key]) {
        hasError = true;
        validation[key] = true; // Marca como inválido se não tiver valor
      } else {
        validation[key] = false; // Marca como válido se tiver valor
      }
    });
    setFieldsValidation(validation); // Atualiza o estado de campos
    return hasError;
  };

  useEffect(() => {
    if (typeDocuments.data && typeDocuments.data.length > 0) {
      setSelectedValue((prevState) => ({
        ...prevState,
        typeDocument: typeDocuments.data[0].id,
      }));
    }
  }, [typeDocuments]);

  // Função para fechar o modal
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue((prevState) => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const [image, setImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Seleciona apenas o primeiro arquivo
    setImage(file);
    if (file) {
      setSelectedFile(file);
    }
  };

  const isValidPhone = (contact) => {
    // Removendo espaços, parênteses e traços
    const cleaned = contact.replace(/[\s()-]/g, '');

    // Expressão regular para validar números de telefone no formato brasileiro
    const phoneRegex = /^(\d{10}|\d{11})$/;

    return phoneRegex.test(cleaned);
  };

  const handleSelectClient = (client) => {
    let phone = '';
    if (client.contacts.length > 0) {
      client.contacts.forEach((contact) => {
        if (isValidPhone(contact.contact)) {
          phone = contact.contact;
        }
      });
    }

    let currentState = '';
    states.forEach((state) => {
      if (state.sigla === client.state) {
        currentState = state.sigla;
      }
    });

    setSelectedValue((prevState) => ({
      ...prevState,
      client_id: client.id,
      alias: client.alias,
      name: client.name,
      document_type_id: client.documentType.id,
      document: client.document,
      phone,
      state: currentState,
      city: client.city
    }));
    console.log(currentState);
    setOpen(false); // Fecha o modal
  };

  const searchClient = async () => {
    setLoading(true);
    if (selectedValue.name === '') {
      setPage(0);
    }
    await searchClients({ param: selectedValue.name });
    handleOpen();
    setLoading(false);
  };

  const searchClientAlias = async () => {
    setLoading(true);
    if (selectedValue.alias === '') {
      setPage(0);
    }
    await searchClients({ alias: selectedValue.alias });
    handleOpen();
    setLoading(false);
  };

  const searchClientByDocument = async () => {
    setLoading(true);

    if (selectedValue.document_type_id === '') {
      notifyMessageError('Pesquisar', 'Informe o tipo de documento');
      setLoading(false);
      return;
    }
    if (selectedValue.document === '') {
      notifyMessageError('Pesquisar', 'Informe o número do documento');
      setLoading(false);
      return;
    }

    if (selectedValue.document_type_id === '' || selectedValue.document === '') {
      setPage(0);
    }
    await searchClients({ document_type_id: selectedValue.document_type_id, document: selectedValue.document });
    handleOpen();
    setLoading(false);
  };

  const clearClientId = async () => {
    if (selectedValue.clientId) {
      setSelectedValue((prevState) => ({
        ...prevState,
        client_id: null,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (!hasErrorFields()) {
        const formData = new FormData();
        Object.entries(selectedValue).forEach(([key, value]) => {
          formData.append(key, value);
        });
        console.log('aqui 2');
        if (image) {
          formData.append('image', image);
        } else {
          formData.delete('image');
        }
        await saveform(formData);
        navigate('/app/orders', { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhoneBlur = async (event) => {
    const { value } = event.target;
    if (!selectedValue.client_id && value) {
      const client = await findByPhoneClient(value);
      if (client) {
        handleSelectClient(client);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Modal */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Selecione um Cliente</DialogTitle>
        <DialogContent>
          <List>
            {clients.data && clients.data.length > 0
              ? clients.data.map((client) => (
                <ListItem key={client.id}>
                  <ListItemText
                    primary={`${client.name}`}
                    secondary={(
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Apelido:
                          {' '}
                          {client.alias}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {client.documentType.name}
                          {' - '}
                          {client.document}
                        </Typography>
                      </>
                  )}
                  />
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      startIcon={<SelectIcon />}
                      onClick={() => handleSelectClient(client)}
                    >
                      Selecionar
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              )) : (
                <>
                  <Alert severity="warning">
                    <center>
                      <Typography> Nenhum Cliente Encontrado </Typography>
                    </center>
                  </Alert>
                </>
              )}
          </List>
          {clients.meta && clients.meta.total > 0 && (
            <>
              <TablePagination
                component="div"
                count={clients.meta.total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[]}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 2,
            position: 'relative',
            mb: 2
          }}
        >
          <Grid
            container
          >
            <Grid
              item
              xs={12}
              sx={{ p: 1 }}
            >
              <Typography variant="h1">Cliente</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Nome do Cliente
              </Typography>
              <TextField
                fullWidth
                required
                onChange={(event) => {
                  handleChange(event);
                  clearClientId();
                }}
                name="name"
                value={selectedValue.name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={searchClient} sx={{ cursor: 'pointer' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {fieldsValidation.name
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Apelido
              </Typography>
              <TextField
                onChange={(event) => {
                  handleChange(event);
                  clearClientId();
                }}
                name="alias"
                value={selectedValue.alias}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={searchClientAlias} sx={{ cursor: 'pointer' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Telefone
              </Typography>
              <InputMask
                mask="(99) 99999-9999" // Máscara para celular no Brasil
                maskChar=" "
                name="phone"
                onBlur={handlePhoneBlur}
                onChange={handleChange}
                value={selectedValue.phone}
              >
                {(inputProps) => <TextField {...inputProps} label="Telefone" variant="outlined" fullWidth />}
              </InputMask>
              {fieldsValidation.phone
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Tipo de Documento
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="tensao-select-label"
                  value={selectedValue.document_type_id}
                  name="document_type_id"
                  onChange={(e) => setSelectedValue({ ...selectedValue, document_type_id: e.target.value })}
                  label="Tensão"
                >
                  {typeDocuments.data && typeDocuments.data.length > 0 && typeDocuments.data.map((type) => (
                    <MenuItem key={`document ${type.id}`} value={type.id}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {fieldsValidation.document_type_id
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Documento
              </Typography>
              <TextField
                onChange={(event) => {
                  handleChange(event);
                  clearClientId();
                }}
                name="document"
                value={selectedValue.document}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={searchClientByDocument} sx={{ cursor: 'pointer' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {fieldsValidation.document
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Selecione um Estado
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="estado-select-label">Estado</InputLabel>
                <Select
                  labelId="estado-select-label"
                  value={selectedValue.state}
                  name="state"
                  onChange={(e) => setSelectedValue({ ...selectedValue, state: e.target.value })}
                  label="Estado"
                >
                  {states.map((estado) => (
                    <MenuItem key={estado.sigla} value={estado.sigla}>
                      {estado.nome}
                    </MenuItem>
                  ))}
                </Select>
                {fieldsValidation.state
                && <Alert severity="error">Campo Obrigatório</Alert>}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Cidade
              </Typography>
              <TextField
                onChange={handleChange}
                name="city"
                value={selectedValue.city}
                fullWidth
              />
              {fieldsValidation.city
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
          </Grid>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 2,
            position: 'relative',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ p: 1 }}
            >
              <Typography variant="h1">Produto</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Nome do Produtos
              </Typography>
              <TextField
                onChange={handleChange}
                name="product_name"
                value={selectedValue.product_name}
                fullWidth
              />
              {fieldsValidation.product_name
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Código Indentificador
              </Typography>
              <TextField
                onChange={handleChange}
                name="code"
                value={selectedValue.code}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Modelo
              </Typography>
              <TextField
                onChange={handleChange}
                name="model"
                value={selectedValue.model}
                fullWidth
              />
              {fieldsValidation.model
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Marca
              </Typography>
              <TextField
                onChange={handleChange}
                name="brand"
                value={selectedValue.brand}
                fullWidth
              />
              {fieldsValidation.brand
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Selecione a Tensão
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="tensao-select-label">Tensão</InputLabel>
                <Select
                  labelId="tensao-select-label"
                  value={selectedValue.tension}
                  name="tension"
                  onChange={(e) => setSelectedValue({ ...selectedValue, tension: e.target.value })}
                  label="Tensão"
                >
                  <MenuItem value="127V">127V</MenuItem>
                  <MenuItem value="220V">220V</MenuItem>
                  <MenuItem value="BIVOLT">BIVOLT (AMBAS TENSÕES)</MenuItem>
                  <MenuItem value="COMBUSTAO">COMBUSTÃO</MenuItem>
                </Select>
              </FormControl>
              {fieldsValidation.tension
              && <Alert severity="error">Campo Obrigatório</Alert>}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Nota Fiscal
              </Typography>
              <TextField
                onChange={handleChange}
                name="nf"
                value={selectedValue.nf}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Data de Emissão da Nota Fiscal
              </Typography>
              <TextField
                fullWidth
                label="Entrada da Order"
                name="date_nf"
                type="date"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedValue.date_nf}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Onde o produto foi vendido
              </Typography>
              <TextField
                onChange={handleChange}
                name="purchased"
                value={selectedValue.purchased}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              lg={12}
              sx={{ p: 1 }}
            >
              <Typography variant="body1" gutterBottom>
                Upload de Foto do Produto
              </Typography>
              <Button
                component="label"
                variant="contained"
                fullWidth
              >
                Selecionar Foto do Produto
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              <Typography variant="body1">{selectedFile ? selectedFile.name : 'Nenhum arquivo selecionado'}</Typography>
              {selectedFile && (
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        variant="square"
                        src={URL.createObjectURL(selectedFile)}
                        alt={selectedFile.name}
                        sx={{ p: 1 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ p: 1 }}
                      primary={selectedFile.name}
                      secondary={`Tamanho: ${(selectedFile.size / 1024).toFixed(2)} KB`}
                    />
                  </ListItem>
                </List>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Grid
          item
          lg={12}
          sx={{
            pt: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            component="label"
            variant="contained"
            onClick={handleSubmit}
            sx={{
              height: 60,
              width: 200,
              backgroundColor: 'green', // Define a cor verde
              '&:hover': {
                backgroundColor: 'darkgreen', // Cor ao passar o mouse
              },
            }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

QuickOrderDetails.propTypes = {
  typeDocuments: PropTypes.any.isRequired,
  searchClients: PropTypes.func.isRequired,
  saveform: PropTypes.func,
  notifyMessageError: PropTypes.func,
  findByPhoneClient: PropTypes.func,
  formErrors: PropTypes.any,
  paginate: PropTypes.func,
  clients: PropTypes.any,
};

export default QuickOrderDetails;
