import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useContext, useState } from 'react';
import { UserContext } from 'src/context/UserContext';
import { SiteContext } from 'src/context/SiteContext';

const Login = () => {
  const navigate = useNavigate();
  const { expiration } = useParams();
  const { loginUser, forgotPassword } = useContext(UserContext);
  const [forgot, setForgot] = useState(false);
  const { setNotifyMessage } = useContext(SiteContext);
  const [values, setValues] = useState({
    email: '',
    password: '',
    isSubmitting: false,
  });

  const login = async (data) => {
    const loginData = await loginUser(data);
    if (loginData) {
      setNotifyMessage({
        text: 'Login realizado com sucesso, aguarde ...',
        type: 'success',
        open: true,
      });
      setTimeout(() => {
        navigate('/app/dashboard', { replace: true });
      }, 2000);
    } else {
      setNotifyMessage({
        text: 'Erro ao realizar o login, usuário ou senha errado',
        type: 'error',
        open: true,
      });
      setValues({
        ...values,
        isSubmitting: false
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      isSubmitting: true
    });
    const data = {
      email: values.email.toLocaleUpperCase(),
      password: values.password
    };
    login(data);
  };

  const handleSubmitForgot = async (e) => {
    e.preventDefault();
    setValues({
      ...values,
      isSubmitting: true
    });
    const data = {
      email: values.email,
    };
    await forgotPassword(data);
    setValues({
      email: '',
      password: '',
      isSubmitting: false
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {`Login | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <>
          {!forgot
            ? (
              <Container maxWidth="sm">
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Entrar
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Entre com seu e-mail e senha
                    </Typography>
                  </Box>
                  {expiration
                    ? (
                      <Alert severity="warning">Sua sessão expirou, faça login novamente</Alert>
                    )
                    : <></>}
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Senha"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={values.isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Entrar
                    </Button>
                  </Box>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Esqueceu a senha ?
                    {' '}
                    <Link
                      onClick={() => { setForgot(!forgot); }}
                      to="/register"
                      variant="h6"
                    >
                      recuperar
                    </Link>
                  </Typography>
                </form>
              </Container>
            )
            : (
              <Container maxWidth="sm">
                <form onSubmit={handleSubmitForgot}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Esqueci minha senha
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Digite seu e-mail para a redefinição de senha
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  {expiration
                    ? (
                      <Alert severity="warning">Sua sessão expirou, faça login novamente</Alert>
                    )
                    : <></>}
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={values.isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Enviar
                    </Button>
                  </Box>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    <Link
                      onClick={() => { setForgot(!forgot); }}
                      to="/register"
                      variant="h6"
                    >
                      Voltar para o Login
                    </Link>
                  </Typography>
                </form>
              </Container>
            )}
        </>
      </Box>
    </>
  );
};

export default Login;
