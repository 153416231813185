import {
  Typography,
  Grid,
  SvgIcon,
  InputAdornment,
  TextField,
  CardContent,
  Card,
  Box,
  Switch,
  Button
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';

const ClientListToolbar = (props) => {
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState({
    isDeleted: false
  });
  const [values, setValues] = useState({
    param: '',
    type: 'P'
  });
  const { search } = props;

  const sendSearch = async () => {
    await search({
      ...values,
      deleted: deleted.isDeleted
    });
  };

  const types = [
    { key: 'P', label: 'FÍSICA' },
    { key: 'J', label: 'JURÍDICA' }
  ];

  const handleChange = async (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase()
    });
  };

  const handleDeleted = async () => {
    setDeleted({ isDeleted: !deleted.isDeleted });
  };

  const buttonNew = () => {
    navigate('/app/client', { replace: true });
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={buttonNew}
        >
          Adicionar Cliente
        </Button>
      </Box>
      <Box sx={{ m: 3 }}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
              >
                <Box>
                  <TextField
                    fullWidth
                    label="Pesquisar (nome, apelido, documento)"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    name="param"
                    value={values.param}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Tipo de Cadastro"
                  name="type"
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{ native: true }}
                  value={values.type}
                  variant="outlined"
                >
                  <option value="">Selecione</option>
                  {types.map((option) => (
                    <option
                      key={option.key}
                      value={option.key}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
              >
                <Box>
                  <Typography>
                    Exibir Excluidos
                  </Typography>
                  <Switch
                    checked={deleted.isDeleted}
                    onChange={handleDeleted}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth style={{ padding: 15, float: 'right' }} color="primary" variant="contained" onClick={sendSearch}><SearchIcon /></Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

ClientListToolbar.propTypes = {
  search: PropTypes.isRequired
};

export default ClientListToolbar;
