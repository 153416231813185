import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Edit as EditIcon,
  Trash2 as TrashIcon
} from 'react-feather';
import formatMoney from 'src/utils/formatMoney';
import DeleteModal from '../modal/DeleteModal';

const OrderServiceListResults = ({
  services,
  exclude,
  paginate,
  reload,
  edit,
  disabled,
  ...rest
}) => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Serviço ?',
  });

  const excludeService = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
      await reload();
    }
  };

  const buttonEdit = (id) => {
    edit(id);
  };

  const deleteService = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeService,
      cancel: () => { }
    });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Serviço
                </TableCell>
                <TableCell>
                  Status Cliente
                </TableCell>
                <TableCell>
                  Status Serviço
                </TableCell>
                <TableCell>
                  Tecnico
                </TableCell>
                <TableCell>
                  Valor
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
                { !disabled
                  ? (
                    <>
                      <TableCell>
                        Excluir
                      </TableCell>
                    </>
                  )
                  : <></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((service) => {
                let styleLine;

                if (service.client_status === 'A' && service.status !== 'F') {
                  styleLine = {
                    backgroundColor: '#17A2B8',
                    fontWeight: 'bold',
                    color: '#fff',
                  };
                }

                if (service.client_status === 'R' && service.status !== 'F') {
                  styleLine = {
                    backgroundColor: '#F08080',
                    fontWeight: 'bold',
                    color: '#fff',
                  };
                }

                if (service.client_status === 'A' && service.status === 'F') {
                  styleLine = {
                    backgroundColor: '#4CAF50',
                    fontWeight: 'bold',
                    color: '#fff',
                  };
                }

                return (
                  <TableRow
                    hover
                    key={service.id}
                  >
                    <TableCell style={{ ...styleLine }}>
                      {service.service}
                    </TableCell>
                    <TableCell style={{ ...styleLine }}>
                      {service.client_status_details}
                    </TableCell>
                    <TableCell style={{ ...styleLine }}>
                      {service.status_details}
                    </TableCell>
                    <TableCell style={{ ...styleLine }}>
                      {service.lastHistory.length > 0
                        ? service.lastHistory[0].technician.name
                        : 'Sem'}
                    </TableCell>
                    <TableCell style={{ ...styleLine }}>
                      { formatMoney(service.value)}
                    </TableCell>
                    <TableCell style={{ ...styleLine }}>
                      <Button onClick={() => { buttonEdit(service.id); }}><EditIcon /></Button>
                    </TableCell>
                    { !disabled
                      ? (
                        <>
                          <TableCell style={{ ...styleLine }}>
                            <Button onClick={() => { deleteService(service.id); }}><TrashIcon /></Button>
                          </TableCell>
                        </>
                      )
                      : <></>}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <DeleteModal modal={deleteModal} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

OrderServiceListResults.propTypes = {
  services: PropTypes.array.isRequired,
  exclude: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  paginate: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default OrderServiceListResults;
