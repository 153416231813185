import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import getInitials from 'src/utils/getInitials';

const TechnicianProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    birth: '',
    document_type_id: '',
    document: '',
    gender: '',
    email: '',
    phone: '',
  });

  const [image, setImage] = useState(null);

  const {
    documenttypes,
    technician,
    gender,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (technician) {
      setValues({
        name: technician.name,
        image: technician.image,
        birth: technician.birth,
        document_type_id: technician.documentType.id,
        document: technician.document,
        gender: technician.gender,
        email: technician.email,
        phone: technician.phone,
      });
    }
  }, [technician]);

  const handleChange = (event) => {
    if (event.target.name !== 'password' && event.target.name !== 'email') {
      setValues({
        ...values,
        [event.target.name]: event.target.value.toUpperCase()
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      image: URL.createObjectURL(event.target.files[0])
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (image) {
      formData.append('image', image);
    }

    if (technician) {
      await updateform(technician.id, formData);
    } else {
      await saveform(formData);
    }
    navigate('/app/technicians', { replace: true });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados do Técnico"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nascimento"
                name="birth"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={Date.now().toString()}
                onChange={handleChange}
                required
                value={values.birth}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Tipo de Documento"
                name="document_type_id"
                onChange={handleChange}
                required
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{ native: true }}
                value={values.document_type_id}
                variant="outlined"
              >
                <option value="">SELECIONE</option>
                {documenttypes.data.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Documento"
                name="document"
                onChange={handleChange}
                required
                value={values.document}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Genero"
                name="gender"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.gender}
                variant="outlined"
              >
                <option value="">SELECIONE</option>
                {gender.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefone"
                name="phone"
                onChange={handleChange}
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Senha"
                name="password"
                type="password"
                required={!technician}
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

TechnicianProfileDetails.propTypes = {
  documenttypes: PropTypes.object,
  technician: PropTypes.object,
  gender: PropTypes.array.isRequired,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default TechnicianProfileDetails;
