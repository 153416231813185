import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {
  Grid, Switch, TextField, Typography
} from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmModal({ modal }) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    id: '',
    status: 'P',
    service: '',
    order_id: '',
    value: '',
    observation: '',
    history: []
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase()
    });
  };

  useEffect(() => {
    setOpen(modal.open);
  }, [modal]);

  useEffect(() => {
    if (modal.contact) {
      setValues({
        ...values,
        id: modal.contact.id,
        contact: modal.contact.contact,
        name: modal.contact.name,
        whatsapp: modal.contact.whatsapp
      });
    } else {
      setValues({
        id: '',
        contact: '',
        name: '',
        whatsapp: false
      });
    }
  }, [modal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    modal.confirm(values);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleWhatsapp = async () => {
    setValues({ ...values, whatsapp: !values.whatsapp });
  };

  return (
    <>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {modal.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <DialogContent dividers>
            <Grid
              spacing={3}
              container
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  required
                  label="Nome"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  required
                  label="Contato"
                  name="contact"
                  onChange={handleChange}
                  value={values.contact}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >

                <Grid container>
                  <Grid
                    item
                    xs={2}
                  >
                    <Typography>Whatsapp</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Switch
                      checked={values.whatsapp}
                      onChange={handleWhatsapp}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" autoFocus onClick={handleClose} color="default">
              Cancelar
            </Button>
            { !modal.disabled
              ? (
                <Button variant="contained" type="submit" color="primary">
                  Confirmar
                </Button>
              )
              : <></> }
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
}

ConfirmModal.propTypes = {
  modal: PropTypes.object
};
