import { Helmet } from 'react-helmet';
import {
  Box, Container, Grid, Typography
} from '@mui/material';
import OrderListResults from 'src/components/order/OrderListResults';
import OrderListToolbar from 'src/components/order/OrderListToolbar';
import { OrderContext } from 'src/context/OrderContext';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TitleComponent from 'src/components/TitleComponent';

const OrderList = () => {
  const { filterStatus } = useParams();
  const {
    orders,
    getOrders,
    deleteOrder,
    searchOrders,
    paginateOrder,
    status,
    getStatus,
  } = useContext(OrderContext);
  useEffect(() => {
    getStatus();
    if (!filterStatus) {
      getOrders();
    } else {
      searchOrders({ status: filterStatus });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Ordem de Serviços | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <TitleComponent title="Lista de Ordens" />
          {status
            ? <OrderListToolbar filterStatus={filterStatus} search={searchOrders} status={status} />
            : <></> }
          <Box sx={{ pt: 3 }}>
            {orders.data && orders.data.length > 0
              ? <OrderListResults orders={orders} exclude={deleteOrder} paginate={paginateOrder} />
              : (
                <>
                  <Grid container alignContent="center" justifyContent="center" alignItems="center" lg={12}>
                    <Typography variant="h3">
                      Nenhum registro encontrado
                    </Typography>
                  </Grid>
                </>
              )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default OrderList;
