import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const ConfigProfileDetails = (props) => {
  const [values, setValues] = useState({
    image: '',
    name: '',
    document: '',
    email: '',
    phone: '',
    waiting_days_ready_order: '',
    waiting_days_sale: '',
  });

  const {
    config,
    updateform,
  } = props;

  const [image, setImage] = useState(null);

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      image: URL.createObjectURL(event.target.files[0])
    });
  };

  useEffect(() => {
    if (config) {
      setValues({
        name: config.name,
        document: config.document,
        email: config.email,
        phone: config.phone,
        waiting_days_ready_order: config.waiting_days_ready_order,
        waiting_days_sale: config.waiting_days_sale,
        image: config.image
      });
    }
  }, [config]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (image) {
      formData.append('image', image);
    } else {
      formData.delete('image');
    }

    await updateform(config.id, formData);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados de funcionamento do sistema"
          title="Configurações"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Box
                component="img"
                sx={{
                  width: 200, // Largura da imagem
                  height: 100, // Altura da imagem
                  borderRadius: 2, // Cantos arredondados (opcional)
                  objectFit: 'cover', // Ajusta a imagem ao contêiner
                }}
                src={values.image}
                alt={values.name}
              />
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome da Empresa"
                name="name"
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <InputMask
                mask="99.999.999/9999-99" // Máscara para celular no Brasil
                maskChar=" "
                name="document"
                onChange={handleChange}
                value={values.document}
              >
                {(inputProps) => <TextField {...inputProps} label="CNPJ" variant="outlined" fullWidth />}
              </InputMask>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <InputMask
                mask="(99) 99999-9999" // Máscara para celular no Brasil
                maskChar=" "
                name="phone"
                onChange={handleChange}
                value={values.phone}
              >
                {(inputProps) => <TextField {...inputProps} label="Telefone" variant="outlined" fullWidth />}
              </InputMask>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Dias de espera para notificar Ordem Finalizada"
                name="waiting_days_ready_order"
                onChange={handleChange}
                value={values.waiting_days_ready_order}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Dias para venda do Equipamento"
                name="waiting_days_sale"
                onChange={handleChange}
                value={values.waiting_days_sale}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ConfigProfileDetails.propTypes = {
  config: PropTypes.object,
  updateform: PropTypes.func.isRequired,
};

export default ConfigProfileDetails;
