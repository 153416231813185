import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import TechnicianProfileDetails from 'src/components/technician/TechnicianProfileDetails';
import HistoryListResults from 'src/components/technician/HistoryListResults';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TechnicianContext } from 'src/context/TechnicianContext';
import { OrderContext } from 'src/context/OrderContext';
import { DocumentTypeContext } from 'src/context/DocumentTypeContext';
import TitleComponent from 'src/components/TitleComponent';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const TechnicianForm = () => {
  const { id, clientId } = useParams();
  const {
    technician,
    getTechnician,
    saveTechnician,
    updateTechnician,
    setTechnician,
  } = useContext(TechnicianContext);

  const { orders, getOrdersByTechnician } = useContext(OrderContext);
  const { documentTypesAll, getAllDocumentTypes } = useContext(DocumentTypeContext);

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const gender = [
    {
      value: 'M',
      label: 'MASCULINO'
    },
    {
      value: 'F',
      label: 'FEMININO'
    },
    {
      value: 'U',
      label: 'INDEFINIDO'
    }
  ];

  useEffect(async () => {
    await getAllDocumentTypes();
    if (id) {
      await getOrdersByTechnician(id);
      await getTechnician(id);
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (technician && documentTypesAll.data && documentTypesAll.data.length > 0) {
        setLoading(false);
      }
    }

    if (!id) {
      if (documentTypesAll.data && documentTypesAll.data.length > 0) {
        setTechnician(null);
        setLoading(false);
      }
    }
  }, [technician, documentTypesAll]);

  return (
    <>
      <Helmet>
        <title>
          {`Product | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <TitleComponent title="Dados do Técnico" />
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                      {technician
                        ? (
                          <Tab style={value === 1 ? tabSelected : tab} label="Histórico de Serviços" {...a11yProps(1)} />
                        )
                        : <></> }
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <TechnicianProfileDetails
                      technician={technician}
                      gender={gender}
                      documenttypes={documentTypesAll}
                      clientId={clientId}
                      saveform={saveTechnician}
                      updateform={updateTechnician}
                    />
                  </TabPanel>
                  {technician
                    ? (
                      <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                        <HistoryListResults orders={orders} />
                      </TabPanel>
                    )
                    : <></> }
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default TechnicianForm;
