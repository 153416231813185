import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  MenuItem
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const OrderListToolbar = (props) => {
  const navigate = useNavigate();
  const { search, status, filterStatus } = props;
  const [values, setValues] = useState({
    id: '',
    client: '',
    product: '',
    technician: '',
    status: 'A'
  });

  useEffect(() => {
    if (filterStatus) {
      setValues({
        ...values,
        status: filterStatus
      });
    }
  }, [filterStatus]);

  const handleSearch = async () => {
    await search(values);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase()
    });
  };

  const buttonNew = () => {
    navigate('/app/order', { replace: true });
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={buttonNew}
        >
          Adicionar Ordem
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={8} lg={2} p={1}>
                <Box>
                  <TextField
                    fullWidth
                    label="Numero da Ordem"
                    onChange={handleChange}
                    name="id"
                    value={values.id}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={3} p={1}>
                <Box>
                  <TextField
                    fullWidth
                    label="Cliente"
                    onChange={handleChange}
                    name="client"
                    value={values.client}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={2} p={1}>
                <Box>
                  <TextField
                    fullWidth
                    label="Produto"
                    onChange={handleChange}
                    name="product"
                    value={values.product}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={2} p={1}>
                <Box>
                  <TextField
                    fullWidth
                    label="Tecnico"
                    onChange={handleChange}
                    name="technician"
                    value={values.technician}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Pesquisar"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2} lg={2} p={1}>
                <TextField
                  id="standard-select-currency"
                  select
                  fullWidth
                  label="Status"
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                >
                  <MenuItem key={0} value="A">
                    Todos
                  </MenuItem>
                  {Object.entries(status).map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                      {option[1]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={2} lg={1} p={1}>
                <Button fullWidth style={{ padding: 15, float: 'right' }} color="primary" variant="contained" onClick={handleSearch}><SearchIcon /></Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

OrderListToolbar.propTypes = {
  search: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  filterStatus: PropTypes.string
};

export default OrderListToolbar;
