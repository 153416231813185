import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery
} from '@mui/material';
import {
  Edit as EditIcon,
  Users as UsersIcon,
  ShoppingBag as ShoppingBagIcon,
  Cpu as CpuIcon,
  Activity as ActivityIcon,
  Tool as ToolIcon,
  Trash2 as TrashIcon
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../modal/DeleteModal';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const OrderListResults = ({ orders, exclude, paginate }) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir esta Ordem?',
  });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate(newPage + 1);
  };

  const buttonEdit = (id) => {
    navigate(`/app/order/${id}`, { replace: true });
  };

  const excludeOrder = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false,
      });
    }
  };

  const deleteOrder = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeOrder,
      cancel: () => {},
    });
  };

  return (
    <>
      <Box sx={{ minWidth: 1050 }}>
        {isSmallScreen ? (
          <Grid container spacing={3}>
            {orders.data.slice(0, limit).map((order) => (
              <Grid item xs={12} md={3} lg={3} key={order.id}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={order.product.name}
                      sx={1}
                      image={order.product.image || '/image/no-image.png'}
                      title={order.product.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        <CpuIcon style={{ marginRight: 15 }} />
                        {order.order_number}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <ShoppingBagIcon style={{ marginRight: 15 }} />
                        {order.product.name}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <UsersIcon style={{ marginRight: 15 }} />
                        {order.client.name}
                        {' '}
                        {order.client.deleted && (
                          <Chip size="small" label="Cliente Excluído" color="secondary" />
                        )}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <ActivityIcon style={{ marginRight: 15 }} />
                        {order.status_details}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        <ToolIcon style={{ marginRight: 15 }} />
                        {order.technician ? order.technician.name : 'Sem'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button onClick={() => buttonEdit(order.id)} fullWidth variant="contained" color="primary">
                      <EditIcon />
                    </Button>
                    {!order.deleted && (
                      <Button onClick={() => deleteOrder(order.id)} fullWidth variant="contained" color="error">
                        <TrashIcon />
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Número</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Técnico</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.data.slice(0, limit).map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_number}</TableCell>
                    <TableCell>{order.product.name}</TableCell>
                    <TableCell>
                      {order.client.name}
                      {order.client.deleted && (
                      <Chip size="small" label="Cliente Excluído" color="secondary" />
                      )}
                    </TableCell>
                    <TableCell>{order.status_details}</TableCell>
                    <TableCell>{order.technician ? order.technician.name : 'Sem'}</TableCell>
                    <TableCell>
                      <Button onClick={() => buttonEdit(order.id)} variant="contained" color="primary">
                        <EditIcon />
                      </Button>
                      {!order.deleted && (
                      <Button onClick={() => deleteOrder(order.id)} variant="contained" color="error">
                        <TrashIcon />
                      </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}
      </Box>
      <TablePagination
        component="div"
        count={orders.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <DeleteModal modal={deleteModal} />
    </>
  );
};

OrderListResults.propTypes = {
  orders: PropTypes.object.isRequired,
  paginate: PropTypes.func.isRequired,
  exclude: PropTypes.func.isRequired,
};

export default OrderListResults;
