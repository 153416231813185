import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Users as UsersIcon,
  Clipboard as OrderIcon,
  Tool as ToolIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import InputIcon from '@material-ui/icons/Input';
import { UserContext } from 'src/context/UserContext';
import BoltIcon from '@mui/icons-material/Bolt';
import NavItem from './NavItem';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/quick-order',
    icon: BoltIcon,
    title: 'Abrir Ordens Rápida'
  },
  {
    href: '/app/documentTypes',
    icon: FileTextIcon,
    title: 'Tipos de Documentos'
  },
  {
    href: '/app/clients',
    icon: UsersIcon,
    title: 'Clientes'
  },
  {
    href: '/app/orders',
    icon: OrderIcon,
    title: 'Ordens'
  },
  {
    href: '/app/technicians',
    icon: ToolIcon,
    title: 'Técnicos'
  },
  {
    href: '/app/users',
    icon: UserIcon,
    title: 'Usuários'
  },
  {
    href: '/app/config',
    icon: SettingsIcon,
    title: 'Configurações'
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const { logedUser } = useContext(UserContext);
  const navigate = useNavigate();

  for (let i = items.length - 1; i >= 0; i--) {
    if (logedUser && logedUser.id !== 1 && items[i].href === '/app/documentTypes') {
      items.splice(i, 1);
    }
  }

  const handleItemClick = (title) => {
    setSelectedItem(title); // Atualiza o estado com o título do item clicado
  };

  const logout = () => {
    sessionStorage.setItem('authorization', null);
    sessionStorage.setItem('user', null);
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={logedUser && logedUser.image ? logedUser.image : ''}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to={`/app/user/${logedUser ? logedUser.id : ''}`}
        />
        <Typography
          color="textPrimary"
          variant="h5"
          sx={{ textAlign: 'center', mt: 2 }}
        >
          {logedUser && logedUser.name ? logedUser.name : ''}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={() => handleItemClick(item.title)}
              sx={{
                backgroundColor: selectedItem === item.title ? 'lightgray' : 'transparent', // Estilo condicional para o item ativo
              }}
            />
          ))}
          {openMobile && (
          <NavItem
            key="Logout"
            title="Logout"
            icon={InputIcon}
            onClick={() => { logout(); }}
          />
          )}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
