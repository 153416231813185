import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: '#fff'
    },
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    default: {
      light: '#e0e0e0',
      main: '#9e9e9e',
      dark: '#757575',
      contrastText: '#fff',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    input: {
      textTransform: 'uppercase'
    }
  },
  shadows,
  typography
});

export default theme;
