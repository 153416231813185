import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import UserListResults from 'src/components/user/UserListResults';
import UserListToolbar from 'src/components/user/UserListToolbar';
import { UserContext } from 'src/context/UserContext';
import { useContext, useEffect } from 'react';
import TitleComponent from 'src/components/TitleComponent';

const UserList = () => {
  const {
    users,
    getUsers,
    deleteUser,
    searchUsers,
    paginateUser,
  } = useContext(UserContext);
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Usuários | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <TitleComponent title="Lista de Usuários" />
          <UserListToolbar search={searchUsers} />
          <Box sx={{ pt: 3 }}>
            {users.data && users.data.length > 0
              ? <UserListResults users={users} exclude={deleteUser} paginate={paginateUser} />
              : <> </>}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default UserList;
