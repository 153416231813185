/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import OrderServiceTimeLineHistory from '../order/OrderServiceTimeLineHistory';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmModal({ modal }) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    id: '',
    status: 'P',
    service: '',
    order_id: '',
    value: '',
    observation: '',
    history: []
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase()
    });
  };

  useEffect(() => {
    setOpen(modal.open);
  }, [modal]);

  useEffect(() => {
    if (modal.service) {
      setValues({
        ...values,
        id: modal.service.id,
        order_id: modal.order_id,
        status: modal.service.status,
        client_status: modal.service.client_status,
        service: modal.service.service,
        value: modal.service.value,
        observation: modal.service.observation,
        history: modal.service.history,
      });
    } else {
      setValues({
        id: '',
        status: 'P',
        client_status: 'P',
        service: '',
        value: '',
        observation: '',
        history: [],
        order_id: modal.order_id
      });
    }
  }, [modal]);

  const handleConfirm = () => {
    // Modificando o valor 'value' e mantendo as outras propriedades inalteradas
    const updatedValues = {
      ...values, // Mantém os valores existentes
      value: values.value
        .replace('R$', '')
        .replace(',', '!')
        .replace('.', '')
        .replace('!', '.'),
    };

    setValues(updatedValues);
    modal.confirm(updatedValues);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const status = {
    P: 'Pendente',
    E: 'Executando',
    F: 'Finalizado',
    N: 'Não Executado',
  };

  const clientStatus = {
    P: 'Pendente',
    A: 'Aprovado',
    R: 'Rejeitado',
  };

  return (
    <>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {modal.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="Aprovação do Cliente"
                name="client_status"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.client_status}
                variant="outlined"
              >
                {Object.entries(clientStatus).map((option) => (
                  <option
                    key={option[0]}
                    value={option[0]}
                  >
                    {option[1]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="Status do Serviço"
                name="status"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.status}
                variant="outlined"
              >
                {Object.entries(status).map((option) => (
                  <option
                    key={option[0]}
                    value={option[0]}
                  >
                    {option[1]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <CurrencyFormat
                label="Valor Orçado"
                name="value"
                data-test="input-value"
                onChange={handleChange}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                fixedDecimalScale
                placeholder="R$ 0,00"
                prefix="R$ "
                value={values.value}
                fullWidth
                customInput={TextField} // Usa o TextField do MUI para o input
                InputProps={{
                  style: { textAlign: 'right' }, // Alinha o texto à direita
                }}
                variant="outlined" // Garante o estilo do campo ser igual ao do TextField
                margin="normal"
              />

            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="Serviço"
                name="service"
                multiline
                rows={4}
                onChange={handleChange}
                value={values.service}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Observação"
                name="observation"
                onChange={handleChange}
                value={values.observation}
                variant="outlined"
              />
            </Grid>
            {
              values.history.length > 0
                ? (
                  <>
                    <Grid style={{ paddingBottom: 20 }}>
                      <Grid xs={12} style={{ padding: 20 }}>
                        <Typography variant="h1" component="h2">
                          Histórico
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <OrderServiceTimeLineHistory histories={values.history} />
                    </Grid>
                  </>
                )
                : <></>
                }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleClose} color="default">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

ConfirmModal.propTypes = {
  modal: PropTypes.object
};
