/* eslint-disable no-restricted-properties */
/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable import/no-extraneous-dependencies */
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const CreatePDF = (budgetData) => {
  const doc = new jsPDF();

  // Adicionando o logo no cabeçalho
  doc.addImage(budgetData.image ? budgetData.image : '/image/no-image.png', 'PNG', 10, 10, 50, 20); // Ajuste posição e tamanho

  // Título do documento
  doc.setFontSize(16);
  doc.text('Orçamento de Serviço', 75, 20); // Centralizado no cabeçalho
  doc.setFontSize(12);

  let currentY = 35; // Ajustando a posição inicial para o texto
  doc.setDrawColor(0); // Cor preta
  doc.setLineWidth(0.5);
  doc.line(10, currentY, 200, currentY); // Linha horizontal entre cabeçalho e corpo
  currentY += 7;
  doc.text(`${budgetData.enterprise}`, 10, currentY);
  doc.text(`CNPJ : ${budgetData.document}`, 120, currentY);
  currentY += 7;
  doc.text(budgetData.email, 10, currentY);
  doc.text(budgetData.phone, 120, currentY);

  // Linha de separação
  doc.setDrawColor(0); // Cor preta
  doc.setLineWidth(0.5);
  currentY += 7;
  doc.line(10, currentY, 200, currentY); // Linha horizontal entre cabeçalho e corpo

  currentY += 7;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam do 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const entryDateFormatted = formatDate(budgetData.entry_date);
  const orderNumberText = `Código: ${budgetData.order_number}`;
  const dateText = `Data de Entrada: ${entryDateFormatted}`;
  doc.text(orderNumberText, 10, currentY);
  doc.text(dateText, 120, currentY); // Data alinhada à direita

  // Informações principais
  const clientInfo = [
    { label: 'Cliente', value: budgetData.client.name },
  ];
  currentY += 7;
  doc.setFontSize(12);
  clientInfo.forEach((info) => {
    doc.text(`${info.label}: ${info.value || 'N/A'}`, 10, currentY);
    currentY += 7;
  });

  // Linha de separação
  doc.setDrawColor(0); // Cor preta
  doc.setLineWidth(0.5);
  doc.line(10, currentY, 200, currentY); // Linha horizontal entre cabeçalho e corpo
  currentY += 7;

  // Título "Produto"
  doc.setFontSize(18);
  doc.text('Produto', 90, currentY);
  doc.setFontSize(12);
  currentY += 7;
  doc.text(`Nome : ${budgetData.product.name}`, 10, currentY);
  currentY += 7;
  doc.text(`Marca :  ${budgetData.product.model}`, 10, currentY);
  currentY += 7;
  doc.text(`Modelo :  ${budgetData.product.brand}`, 10, currentY);
  currentY += 7;

  // Linha de separação
  doc.setDrawColor(0); // Cor preta
  doc.setLineWidth(0.5);
  doc.line(10, currentY, 200, currentY); // Linha horizontal entre cabeçalho e corpo
  currentY += 8;

  // Título "Motivo da Manutenção"
  doc.setFontSize(18);
  doc.text('Motivo da Manutenção', 70, currentY);
  doc.setFontSize(12);
  currentY += 7;
  doc.setFontSize(10);
  doc.text(budgetData.observation, 10, currentY);
  currentY += 7;

  // Linha de separação
  doc.setFontSize(12);
  doc.setDrawColor(0); // Cor preta
  doc.setLineWidth(0.5);
  doc.line(10, currentY, 200, currentY);
  currentY += 7;

  // Título "Serviços"
  doc.setFontSize(18);
  doc.text('Serviços', 90, currentY);
  doc.setFontSize(12);

  // Lista de serviços com linhas pontilhadas
  const { services } = budgetData;

  // Função para desenhar linha pontilhada manualmente
  const drawDashedLine = (x1, y1, x2, y2) => {
    const dashLength = 2; // Tamanho do ponto
    const spaceLength = 2; // Tamanho do espaço entre os pontos
    const totalLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    const dashCount = Math.floor(totalLength / (dashLength + spaceLength));

    const dx = (x2 - x1) / dashCount;
    const dy = (y2 - y1) / dashCount;

    for (let i = 0; i < dashCount; i++) {
      const startX = x1 + dx * i;
      const startY = y1 + dy * i;
      const endX = startX + dx * 0.5; // Tamanho do ponto
      const endY = startY + dy * 0.5; // Tamanho do ponto
      doc.line(startX, startY, endX, endY);
    }
  };

  // Desenhando cada serviço
  services.forEach((service, index) => {
    if (service.client_status === 'A') {
      currentY += 7;
      doc.text(service.service, 10, currentY);

      // Linha pontilhada após o serviço
      const lineStartX = 10;
      const lineEndX = 200;
      const lineY = currentY + 2;
      drawDashedLine(lineStartX, lineY, lineEndX, lineY); // Desenhando a linha pontilhada
    }
  });

  // Calculando o Y após todos os serviços
  const finalY = currentY + 15;

  // Adicionando o "Total" alinhado à direita após os serviços
  doc.setFontSize(18);
  const totalText = 'Total';
  const totalValue = budgetData.amount_order; // Exemplo de valor
  doc.text(totalText, 10, finalY);
  doc.text(`R$ ${totalValue}`, 190, finalY, { align: 'right' });

  // Linha de separação no rodapé
  const pageHeight = doc.internal.pageSize.height;
  doc.line(10, pageHeight - 40, 200, pageHeight - 40);

  // Espaço para assinaturas
  doc.setFontSize(12);
  doc.text('Assinatura da Empresa:', 10, pageHeight - 30);
  doc.line(60, pageHeight - 30, 200, pageHeight - 30); // Linha para assinatura da empresa

  doc.text('Assinatura do Cliente:', 10, pageHeight - 15);
  doc.line(60, pageHeight - 15, 200, pageHeight - 15); // Linha para assinatura do cliente

  // Rodapé
  doc.setFontSize(10);
  const dateGenerate = `Gerado em: ${new Date().toLocaleDateString()} às ${new Date().toLocaleTimeString()}`;
  // Calculando a posição X para alinhar à direita
  const pageWidth = doc.internal.pageSize.width;
  const textWidth = doc.getTextWidth(dateGenerate);
  const xPosition = pageWidth - textWidth - 10; // 10 é a margem direita

  doc.text(dateGenerate, 10, pageHeight - 5);

  // Adicionando o número da página
  const totalPages = doc.internal.getNumberOfPages();
  doc.text(`Página ${doc.internal.getCurrentPageInfo().pageNumber} de ${totalPages}`, pageWidth - 30, pageHeight - 5);

  // Salvar o arquivo PDF
  doc.save(`Orcamento_${budgetData.order_number}.pdf`);
};

export default CreatePDF;
